import { GET_ALL_STORES_WHIT_CONSULTANT, GET_WORKING_DAYS, GET_RESTING_DAYS, SEND_DATA_BOOKING_CONSUTANT, _TOKEN, GET_DAYS_EVENTS_STORE, DELETE_BOOKING_CONSULTANT, RESPONSE_DELETE_BOOKING_CONSULTANT, GET_BOOKINGS_CONSULTANTS } from '../../../constants'
import swal from "sweetalert";
import { getLimitConsultant, storeWithEvents } from '../../../utils'
// import swal from "sweetalert";
// import { useHistory } from "react-router-dom";
// let history = useHistory();

export const getAllStores = () => ({
  type: GET_ALL_STORES_WHIT_CONSULTANT,
  payload: async () => {
    const res = await fetch("/agenda-consultor", {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })
    const data = await res.json();
    console.log('storesActions', data)
    if (res.ok) return data;
    else return data;
  }
});

/*export const getAllStores = () => ({
  type: GET_ALL_STORES_WHIT_CONSULTANT,
  payload: async () => {
    const res = await fetch("/agenda-consultor", {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })
    const data = await res.json();
    console.log('storesActions', data)
    if (res.ok) return data;
    else return data;
  }
});*/

export const getWorkingDays = (id) => ({
  type: GET_WORKING_DAYS,
  payload: async () => {
    const res = await fetch(`/agenda-consultor/${id}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })
    const data = await res.json();
    console.log('getWorkingDayssssssssss', data)
    const limitData = getLimitConsultant(data.workingDay, data.restingDay, data.bookingConsultant)

    if (res.ok) return { data, limitData };

    else return null;
  }
});

export const getRestingDays = (id) => ({
  type: GET_RESTING_DAYS,
  payload: async () => {
    const res = await fetch(`/agenda-consultor/${id}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })
    const data = await res.json();
    console.log('getRestingDaysssssssssssssssssssssss', data)
    const limitData = getLimitConsultant(data.restingDay, data.bookingConsultant)

    if (res.ok) return { data, limitData };

    else return null;
  }
});

export const getBookingsConsultants = (id) => ({
  type: GET_BOOKINGS_CONSULTANTS,
  payload: async () => {
    const res = await fetch(`/get-bookings-consultant/${id}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })

    const data = await res.json();
    console.log('datosbookinggggggg', data)
    if (res.ok) return data;
    else throw data;
  }
});

export const getDaysEventsStore = (id) => ({
  type: GET_DAYS_EVENTS_STORE,
  payload: async () => {
    const res = await fetch(`/agenda-consultor/store/${id}`, {
      method: "GET",
      headers: {
        "Authorization": "Bearer " + _TOKEN
      }
    })
    const data = await res.json();
    console.log('getDaysEventsStore', data)
    const eventsDayStore = storeWithEvents(data.events)

    if (res.ok) return { eventsDayStore };

    else return null;
  }
});

export const sendBookingConsultant = (dataBooking) => ({
  type: SEND_DATA_BOOKING_CONSUTANT,
  payload: async () => {
    console.log('bookingACtion------------', dataBooking)
    const response = await fetch('/agenda-consultor/reserva-consultor', {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      // mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(dataBooking) // body data type must match "Content-Type" header
    });
    if (response.ok) {
      // let history = useHistory();
      swal("Tu reserva se guardo con exito! \n \n Si no recibís el mail, recordá revisar correo No Deseado")

        .then((value) => {
          window.location.href = "/";
        });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  }
});

export const deleteBookingConsultant = (id) => ({

  type: DELETE_BOOKING_CONSULTANT,
  payload: async () => {
    console.log("hola")
    const res = await fetch(`/delete-booking-consultant/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + _TOKEN,
        },
      }
    );

    const data = await res.json();
    console.log("deletedatadeletedata", data);
    if (res.ok) return data;
    else return null;
  },
});

export const emailDeleteBookingConsultant = (id, data) => ({
  type: RESPONSE_DELETE_BOOKING_CONSULTANT,
  payload: async () => {
    console.log("responseDeleteBooking------------", data);
    const response = await fetch(`/delete-booking-consultant/${id}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        // mode: 'cors', // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
      }
    );
    if (response.ok) {
      // let history = useHistory();
      swal("Tu reserva se canceló con exito!").then((value) => {
        window.location.href = "/";
      });
      return response.json(); // parses JSON response into native JavaScript objects
    }
  },
});

