import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CircularProgress, Button, Grid } from "@material-ui/core";
import { ZONES, getLimit } from "../../utils";
import { useEvents } from "../../hooks";
import { Calendar } from "../../components/Calendar";
import { EmptyCalendar } from "../../components/EmptyCalendar";
import { GoBackFabBtn } from "../../components/GoBackButton";
import moment from "moment";
import EventBannerM from "../../assets/BannerMobile/mheaderRes-min.jpg";
import EventBannerD from "../../assets/BannerDesktop/headerEvent-min.jpg";
import { Footer } from "../../components/Footer";

import "./styles.css";

const EventBooking = () => {
  const {
    getSingleEvent,
    singleEvent,
    bookingsEvent,
    sendDataBooking,
    isFetching,
    //shortTimeSpan,
    //setRestart,
  } = useEvents();

  const [selectedZone, setSelectedZone] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectStore, setSelectStore] = useState("");
  const [emailValidate, setEmailValidate] = useState("inicial");
  const [phoneValidate, setPhoneValidate] = useState("inicial");
  const [selectedDay, setSelectDay] = useState(null);
  const [newUserEvent, setNewUserEvent] = useState({});
  const [timeEventHour, setTimeEventHour] = useState(null);
  const [dateBooking, setDateBooking] = useState(null);
  const [dataEvent, setDataEvent] = useState(null);
  const [dataEventStore, setDataEventStore] = useState(null);
  const [filterStores, setFilterStores] = useState([]);
  const [noDays, setNoDays] = useState(false);
  //const hoy = new Date();
  const today = moment().subtract(3, "hours").format("YYYY-MM-DD");
  //let timenow = moment().subtract(3, 'hours').format('HH:mm');
  //console.log('today', today);
  //console.log('hoy', hoy);

  const { id } = useParams();

  useEffect(() => {
    // setRestart()
    return () => {
      restartLocalState();
    };
  }, []);

  useEffect(() => {
    if (id)
      getSingleEvent(id).then(({ action }) => {
        setSelectedZone(action.payload.event?.stores[0].city);
      });
  }, [id]);

  useEffect(() => {
    console.log("timeselect", timeEventHour);
  }, [timeEventHour]);

  const filterPastDates = (data) => {
    const today = moment().startOf("day"); // La fecha actual sin la parte de la hora
    return Object.keys(data)
      .filter((date) => moment(date).isSameOrAfter(today)) // Filtra solo las fechas actuales o futuras
      .reduce((acc, date) => {
        acc[date] = data[date];
        return acc;
      }, {});
  };

  useEffect(() => {
    if (singleEvent) {
      let data = getLimit(
        singleEvent?.stores,
        singleEvent?.timespanstoreevents,
        bookingsEvent
      );
      setDataEvent(data);
    }
  }, [singleEvent]);
  useEffect(() => {
    if (selectedZone) {
      const filteredStores = singleEvent.stores
        .filter(
          (store) => store.city.toUpperCase() === selectedZone.toUpperCase()
        )
        .sort((a, b) => a.name.localeCompare(b.name)); // Ordenar alfabéticamente

      //console.log("tessssssssssssssst", filteredStores);

      // Si solo hay una sucursal, seleccionarla automáticamente
      setFilterStores(filteredStores);
      if (filteredStores.length === 1) {
        setSelectDay(null);
        //console.log("filteredStores", filteredStores); // Seleccionar automáticamente la sucursal
        const timeFound = singleEvent.timespanstoreevents.filter((time) => {
          return time.storeId === Number(filteredStores[0].id);
        });
        // console.log("timeFound", timeFound);
        setSelectStore(filteredStores[0]);
        setSelectedTime(timeFound);
        //console.log("dataEventdataEvent", dataEvent);
        const hasFutureDate = timeFound.some((block) =>
          block.day
            .split(", ")
            .some((date) => moment(date, "DD/MM/YYYY").isSameOrAfter(today))
        );

        // Setear noDays según el resultado
        setNoDays(!hasFutureDate);

        const dates = filterPastDates(dataEvent[Number(filteredStores[0].id)]);
        //console.log("dates", dates);
        setDataEventStore(dates);
      }
    }
  }, [selectedZone]);

  const handleZone = (zone) => {
    setSelectedZone(zone);
    setSelectStore("");
    setSelectedTime(null);
    setDateBooking(null);
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  /*if (isMobile) {
    ZONES[2] = "GBA";
    ZONES[3] = "MDQ";
  }*/

  const handleChange = (e) => {
    setNewUserEvent({ ...newUserEvent, [e.target.name]: e.target.value });
  };

  const requeridoEmail = (e) => {
    let expresion = /\w+@\w+.[a-z]/;
    //     \w (es texto)
    //     . (es punto)
    if (newUserEvent.email !== "" && expresion.test(newUserEvent.email)) {
      setEmailValidate("mostrar");
    } else {
      // el input esta vacio
      setEmailValidate("nomostrar");
    }
  };

  const requeridoPhone = (e) => {
    let expresion = /^(0|[1-9]\d*)$/;
    //     \w (es texto)
    //     . (es punto)
    if (newUserEvent.tel !== "" && expresion.test(newUserEvent.tel)) {
      setPhoneValidate("mostrar");
    } else {
      // el input esta vacio
      setPhoneValidate("nomostrar");
    }
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    sendDataBooking({
      ...newUserEvent,
      eventId: singleEvent.id,
      selectTime: timeEventHour,
      selectDay: dateBooking.format_date,
      storeId: selectStore.id,
    });
  };

  const handleSelectSltore = (event) => {
    if (event.target.value === "seleccione") {
      setSelectStore("");
      setSelectedTime(null);
      setSelectDay(null);
    } else {
      setDateBooking(null);
      setSelectDay(null);
      setSelectedTime(null);
      setTimeEventHour(null);

      const found = singleEvent.stores.filter((ev) => {
        return ev.id === Number(event.target.value);
      });

      const timeFound = singleEvent.timespanstoreevents.filter((time) => {
        return time.storeId === Number(event.target.value);
      });
      //console.log("found", found);
      setSelectStore(found[0]);
      setSelectedTime(timeFound);
      //const today = moment().startOf("day");
      const hasFutureDate = timeFound.some((block) =>
        block.day
          .split(", ")
          .some((date) => moment(date, "DD/MM/YYYY").isSameOrAfter(today))
      );

      // Setear noDays según el resultado
      setNoDays(!hasFutureDate);

      //console.log("bbbbbbbbbbbbbb", timeFound);
      const dates = filterPastDates(dataEvent[Number(event.target.value)]);
      setDataEventStore(dates);
    }
  };

  const restartLocalState = () => {
    setSelectedZone("");
    setSelectedTime(null);
    setSelectStore("");
    setSelectDay(null);
    setTimeEventHour(null);
    setDateBooking(null);
  };

  const bannerevent = isMobile ? EventBannerM : EventBannerD;

  return (
    <>
      <div className="bannerEvent">
        <img className="bannerEventImg" src={bannerevent} alt="banner" />
      </div>

      <div className="react-container">
        {singleEvent && selectedZone && (
          <>
            {/*<div className="eventTitle">
              <h1> {singleEvent.name}</h1>
            </div>*/}
            <div className="container-center-image">
              {isMobile ? (
                <img
                  src={
                    "../../../../uploads/events/" + singleEvent.imageFullMobile
                  }
                  className="imgbannermobile"
                />
              ) : (
                <img
                  src={"../../../../uploads/events/" + singleEvent.imageFull}
                  className="imgbanner"
                />
              )}
            </div>
            <div className="eventDescription">
              <h5>{singleEvent.descriptionEvent}</h5>
            </div>
            <div className="calendar-container">
              <div className="calendar ">
                {/*<select className="select-dropdown">
                    <option>Selecciona una ciudad </option>
                  </select>*/}
                {ZONES.map((zone) => {
                  let active = selectedZone.toUpperCase() === zone;
                  let include = singleEvent.stores
                    .map((store) => store.city.toUpperCase())
                    .includes(zone);

                  let zone2 = zone;
                  //if(isMobile){
                  if (zone2 === "GRAN BUENOS AIRES") zone2 = "GBA";
                  if (zone2 === "MAR DEL PLATA") zone2 = "MDQ";
                  //}
                  return (
                    <div
                      key={zone}
                      onClick={() => (include ? handleZone(zone) : null)}
                      className={`zone ${active ? "active" : ""}`}
                      style={{
                        borderBottom: active ? "1 rem solid" : "none",
                        display: !include && "none",
                        fontWeight: active ? 800 : 500,
                      }}
                    >
                      {zone2}
                    </div>
                  );
                })}
              </div>

              <div className="form-calendar">
                <form onSubmit={handleSubmit} className="order input-container">
                  <div className="div_form">
                    <div className="grid-input sucursal">
                      <label className="label">SUCURSAL</label>
                      <select
                        name="store"
                        className="input"
                        onChange={handleSelectSltore}
                        value={selectStore.id}
                      >
                        <option value="seleccione">Seleccione</option>;
                        {filterStores.map((st) => (
                          <option key={st.id} value={st.id}>
                            {st.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {noDays ? (
                      <label className="labelMsg">
                        Lo sentimos!
                        <br />
                        Este evento ya finalizó.
                        <br />
                        Te esperamos en otras sucursales.
                      </label>
                    ) : (
                      <>
                        <div className="order grid-calendario">
                          <div className="divCalendar">
                            <label className="label">
                              ¿CUANDO DESEA ASISTIR?
                            </label>
                            <div>
                              {selectedTime && (
                                <Calendar
                                  setSelectDay={setSelectDay}
                                  selectedDay={selectedDay}
                                  setDateBooking={setDateBooking}
                                  dataEventStore={dataEventStore}
                                />
                              )}
                              {!selectedTime && <EmptyCalendar />}
                            </div>
                          </div>
                        </div>

                        {selectedTime && dateBooking && (
                          <div className="order grid-time">
                            <label className="label">ELEGI UN HORARIO</label>
                            <div className="grid-item-time">
                              {Object.keys(
                                dataEventStore[dateBooking.datedb]
                              ).map((time) => {
                                let timeselect;
                                if (timeEventHour) {
                                  timeselect = timeEventHour;
                                  if (timeselect[0] > 2) {
                                    timeselect = 0 + timeselect;
                                  }
                                }
                                //console.log("timeselect", timeEventHour);
                                //console.log("time", time);
                                let active = timeselect === time;
                                let inactive =
                                  dataEventStore[dateBooking.datedb][time]
                                    .cupos <= 0;
                                if (time[0] > 2) {
                                  time = 0 + time;
                                }
                                let hora = moment().format("HH:mm");

                                if (today === dateBooking.datedb)
                                  if (time < hora && !active) {
                                    active = false;
                                    inactive = true;
                                  }
                                return (
                                  <div
                                    key={time}
                                    onClick={() =>
                                      !inactive
                                        ? setTimeEventHour(
                                            time.charAt(0) === "0"
                                              ? time.substring(1)
                                              : time
                                          )
                                        : null
                                    }
                                    className="item-time"
                                    style={{
                                      backgroundColor: active
                                        ? "#a5a5a5"
                                        : inactive
                                        ? "gray"
                                        : "white",

                                      color: active ? "white" : "#4a4a4a",

                                      cursor: inactive
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                  >
                                    {inactive ? "AGOTADO" : time + " HS."}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        <div className="form-main">
                          <div className="grid-input">
                            <label className="label">NOMBRE</label>
                            <input
                              name="nombre"
                              className="input"
                              type="text"
                              onChange={handleChange}
                              // onChange={(ev) => ({
                              //   ...dataForm,
                              //   nombre: ev.target.value,
                              // })}
                            />
                          </div>
                          <div className="grid-input">
                            <label className="label">APELLIDO</label>
                            <input
                              name="apellido"
                              className="input"
                              type="text"
                              onChange={handleChange}
                              // onChange={(ev) => ({
                              //   ...dataForm,
                              //   apellido: ev.target.value,
                              // })}
                            />
                          </div>
                          <div className="grid-input">
                            <label className="label">EMAIL</label>
                            <input
                              name="email"
                              className="input"
                              // className={emailValidate == "inicial" ? "" : emailValidate == "mostrar"  ? "is-valid" : "is-invalid" }
                              type="email"
                              onChange={handleChange}
                              onBlur={requeridoEmail}
                              // onChange={(ev) => (dataForm.email = ev.target.value)}
                            />
                            {emailValidate === "nomostrar" && (
                              <div className="invalid-feedback">
                                Ingrese un email válido
                              </div>
                            )}
                          </div>
                          <div className="grid-input">
                            <label className="label">TELEFONO</label>
                            <input
                              name="tel"
                              className="input"
                              type="text"
                              onChange={handleChange}
                              onBlur={requeridoPhone}
                              // onChange={(ev) => (dataForm.tel = ev.target.value)}
                            />
                            {phoneValidate === "nomostrar" && (
                              <div className="invalid-feedback">
                                Ingrese un número de teléfono válido
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {!noDays && (
                    <div className="containerButton">
                      <Button
                        className="buttonFormAgenda"
                        type="submit"
                        variant="contained"
                        // onClick={onClick}
                        disabled={
                          isFetching ||
                          !newUserEvent.email ||
                          !newUserEvent.nombre ||
                          !timeEventHour
                        }
                      >
                        {isFetching && <CircularProgress size={14} />}
                        {!isFetching && "AGENDAR"}
                      </Button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export { EventBooking };
